import React, { Component } from 'react'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { Link, withRouter } from "react-router-dom";
import "./styles.css"
import BreadCrumb from "../../components/BreadCrumb"
import { api, config } from '../../../../services/api';
import orderBy from "lodash/orderBy";
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Services extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      dataFiltered: [],

      allCategories: [],
      category: "",
      status: "",

      // react table
      query: '',
      columnToSort: '',
      sortDirection: 'desc',
      loading: false,
      filtered: [],
    }
  }

  componentDidMount() {
    this.loadServices();
    this.loadCategories();
  }

  loadServices = async () => {
    try {
      const services = await api.get(config.endpoint + `services`);
      console.log("teste");
      await this.setState({ data: services.data.service, dataFiltered: services.data.service })
    }
    catch (err) {
      console.log(err);
    }
  }

  loadCategories = async () => {
    try {
      const categories = await api.get(config.endpoint + `categories`);
      this.setState({ allCategories: categories.data })
    }
    catch (err) {
      console.log(err);
    }
  }

  handleChange = async (e) => {
    let value = e.target.value;
    await this.setState({ [e.target.name]: value });
    const { category, status, data } = this.state;

    if (category !== "" && status !== "") {
      // se tiver categoria e status selecionados 
      if (status === "Ativo") {
        await this.setState({
          dataFiltered: data.filter(c => c.Categories.Name === category && c.Active === 1)
        });
      } else {
        await this.setState({
          dataFiltered: data.filter(c => c.Categories.Name === category && c.Active === 0)
        });
      }
    }
    else if (category === "" && status === "") {
      // se não tiver selecionado nada
      await this.setState({ dataFiltered: data });
    }
    else if (category !== "" && status === "") {
      // se só categoria for selecionada
      await this.setState({ dataFiltered: data.filter(c => c.Categories.Name === category) });
    }
    else if (category === "" && status !== "") {
      // se só status for selecionado
      if (status === "Ativo") {
        await this.setState({ dataFiltered: data.filter(c => c.Active === 1) });
      } else {
        await this.setState({ dataFiltered: data.filter(c => c.Active === 0) });
      }
    }
  }

  render() {
    const lowerCaseQuery = this.state.query.toLowerCase();

    const { dataFiltered, query, allCategories, category, status } = this.state;
    return (
      <div>
        <BreadCrumb data={['Início', 'Serviços']}
          path={['home', 'services']} />
        <div className="col-md-12 header-service-consult" id="telaServicos" >
          <div>
            <button className="btn btn-sucesso"
              onClick={() => this.props.history.push("novo-servico")}> + Novo Serviço </button>
          </div>
          <div className="pesquisar">
            <i id="iconepesquisar" className="fa fa-search"></i>
            <input
              type="text"
              style={{ paddingLeft: "30px" }}
              className="form-control foco-input"
              placeholder="Pesquisar por Descrição ou Categoria"
              value={query}
              onChange={e => this.setState({ query: e.target.value })}
            />
          </div>
          <div className="textoServices textCategoria">
            <span><strong>Categoria:</strong></span>
          </div>
          <div>
            <select
              className="form-control foco-input inputServices"
              onChange={e => {
                this.handleChange(e);
              }}
              name="category"
              value={category}
            >
              <option key="" value="">
                Selecione
                    </option>
              {allCategories.map(c => {
                return (
                  <option key={c.id} value={c.Name}>
                    {c.Name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="textoServices textoStatus">
            <span><strong>Status:</strong></span>
            <select
              className="form-control foco-input inputStatus"
              name="status"
              value={status}
              onChange={e => {
                this.handleChange(e);
              }}
            >
              <option value="">Ambos</option>
              <option value="Ativo">Ativo</option>
              <option value="Inativo">Inativo</option>
            </select>

          </div>
        </div>
        <br />
        <ReactTable
          style={{ width: '100%', textAlign: 'center' }}
          data={orderBy(query ?
            dataFiltered.filter(x =>
              x['Description']?.toString().toLowerCase().includes(lowerCaseQuery) ||
              x['Categories']?.['Name']?.toString().toLowerCase().includes(lowerCaseQuery) &&
              x['Active'] === 1
            )
            :
            dataFiltered,
            this.state.columnToSort,
            this.state.sortDirection
          )}
          onClick={() => this.setState({ filtered: [] })}
          columns={[
            {
              Header: "Código",
              id: "id",
              accessor: props => Number(props.id), // FUNÇÃO PARA SETAR EM ORDEM NUMÉRICA
              headerClassName: "text-left",
              width: 84,
              Cell: props => (
                <Link id="linkServico" to={{ pathname: `EditarServico#${props.original.id}` }} title="Clique para editar">
                  <a>
                    {props.value}
                  </a>
                </Link>
              )
            },
            {
              Header: "Categoria",
              accessor: "Categories.Name",
              headerClassName: "text-left",
              width: 200
            },
            {
              Header: "Serviços",
              accessor: "Description",
              headerClassName: "text-left",
              // width: 270,
            },
            {
              Header: "Sub-Categoria",
              accessor: "SubCategories.Name",
              headerClassName: "text-left",
              width: 150,
              Cell: props => <span>{props.value || "---"}</span>,

            },
            {
              Header: "Valor",
              accessor: "Price",
              headerClassName: "text-left",
              width: 100,
              Cell: props => <span>R$ {parseFloat(props.value).toFixed(2).replace('.', ',')}</span>,
            },
            {
              Header: "Status",
              accessor: "Active",
              headerClassName: "text-left",
              width: 90,
              // Cell: props => ( <button className="form-control" id="botaoReactTable" style={{  backgroundColor: props.value ? "green" : "red"}}>
              //     {props.value == 1 ? "Ativo" : "Inativo"} {props.value == 1 ? "Ativo" : "Inativo"} </button>)
              Cell: props => <div style={{ cursor: 'pointer', borderRadius: 5, fontSize: 15 }} className={` ${props.value === 0 ? 'btn-danger' : 'btn-success'}`}> {props.value === 1 ? "Ativo" : "Inativo"}</div>
            },
            {
              Header: "Ações",
              accessor: "Actions",
              headerClassName: "text-left",
              filterable: false,
              width: 90,
              Cell: props => (
                <Link to={{ pathname: `EditarServico#${props.original.id}` }} title="Clique para editar">
                  <FontAwesomeIcon style={{ color: 'black' }} cursor='pointer' icon={faEdit} />
                </Link>
              )
            }
          ]}
          defaultPageSize={10}
          className=""
          loading={this.state.loading}
          showPagination={true}
          sortable={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: "id",
              desc: false
            }
          ]}
          previousText="Anterior"
          nextText="Próximo"
          loadingText="Carregando..."
          noDataText="Não há informação"
          pageText="Página"
          ofText="de"
          rowsText="linhas"
        />

      </div>

    );
  }
}

export default withRouter(Services);