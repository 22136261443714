import React from 'react'
import { handleBankSlip } from '../../../../reduxCart/cartAction';
import { useDispatch, useSelector } from 'react-redux'
import { formatMoney } from '../../../../../../normalize'

export default function SelectInstallments({ total }) {
  const { cartReducer: { bankSlip } } = useSelector(state => state);
  const dispatch = useDispatch();

  return (
    <select
      id='select-installments'
      className='form-control foco-input'
      value={bankSlip.installments}
      onChange={e => dispatch(handleBankSlip(bankSlip, e.target.value, 'installments'))}
    >
      {options(total)}
    </select>
  )
}

function options(total) {
  if (total < 100) {
    return <option>1x de {formatMoney(total)}</option>
  }
  if (total >= 100 && total < 300) {
    return (
      <>
        <option value={1}>1x de {formatMoney(total)} sem juros</option>
        <option value={2}>2x de {formatMoney(total / 2)} sem juros</option>
      </>
    )
  }
  if (total >= 300 && total < 400) {
    return (
      <>
        <option value={1}>1x de {formatMoney(total)} sem juros</option>
        <option value={2}>2x de {formatMoney(total / 2)} sem juros</option>
        <option value={3}>3x de {formatMoney(total / 3)} sem juros</option>
      </>
    )
  }
  if (total >= 400) {
    return (
      <>
        <option value={1}>1x de {formatMoney(total)} sem juros</option>
        <option value={2}>2x de {formatMoney(total / 2)} sem juros</option>
        <option value={3}>3x de {formatMoney(total / 3)} sem juros</option>
        <option value={4}>4x de {formatMoney(total / 4)} sem juros</option>
      </>
    )
  }
}