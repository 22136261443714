import React from "react";
import "./index.css";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "./desktop/store/pages/Login";
import AdminLogin from "./desktop/admin/pages/Login";

import AdminLayout from "./desktop/layout/Admin.js";
import StoreLayout from "./desktop/layout/Store";

const Desktop = () => (
  <Switch>
    <Redirect exact from="/" to="/store" />{" "}
    <Redirect from="/login/logar?login&senha" to='/login' preserveQueryString={true} />{" "}
    <Route path="/store" component={StoreLayout} />{" "}
    <Route path="/admin" component={AdminLayout}/>{" "}
    <Route path="/login" component={Login} />{" "}
    <Route exact path="/admin-login" component={AdminLogin} />{" "}
  </Switch>
);

export default Desktop;
