export function changeValue(payload, payloadType) {
    return {
        type: 'CHANGE_VALUE',
        payload: payload,
        payloadType: payloadType
    }
}

export function filterCategory(category, array) {
    category.checked = true
    for (let i = 0; i < array.length; i++) {
        if (array[i].id === category.id) {
            array[i].checked = false

            if (category.Name === "Treinamentos") {
                return [
                    {
                        type: 'CHANGE_VALUE',
                        payload: array.filter(c => c.id !== category.id),
                        payloadType: 'categoriesFilterd'
                    },
                    {
                        type: 'CHANGE_VALUE',
                        payload: [],
                        payloadType: 'subCategories'
                    },
                    {
                        type: 'CHANGE_VALUE',
                        payload: false,
                        payloadType: 'online'
                    },
                    {
                        type: 'CHANGE_VALUE',
                        payload: false,
                        payloadType: 'presencial'
                    },
                ]
            }

            return {
                type: 'CHANGE_VALUE',
                payload: array.filter(c => c.id !== category.id),
                payloadType: 'categoriesFilterd'
            }
        }
    }
    let newArray = array.slice()
    newArray.push(category)
    return {
        type: 'CHANGE_VALUE',
        payload: newArray,
        payloadType: 'categoriesFilterd'
    }
}

export function clearFilter(categories) {
    let array = categories
    for (let i = 0; i < array.length; i++) {
        array[i].checked = false
    }

    return ([
        {
            type: 'CHANGE_VALUE',
            payload: array,
            payloadType: 'categories'
        },
        {
            type: 'CHANGE_VALUE',
            payload: [],
            payloadType: 'categoriesFilterd'
        },
        {
            type: 'CHANGE_VALUE',
            payload: [],
            payloadType: 'subCategories'
        },
        {
            type: 'CHANGE_VALUE',
            payload: false,
            payloadType: 'online'
        },
        {
            type: 'CHANGE_VALUE',
            payload: false,
            payloadType: 'presencial'
        }
    ])
}

export function checkQuery(categories, categoriesFilterd) {
    if (window.location.href.includes('?category=')) {
        const category = window.location.href.split('?category=')[1]
        for (let i = 0; i < categories.length; i++) {
            if (categories[i].Name.indexOf(category) > -1) {
                return [
                    filterCategory(categories[i], categoriesFilterd)
                ]
            }
        }
    } else {
        return [
            clearFilter(categories)
        ]
    }
}