import React, { useState, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

function Info({ comments }) {

    const [modal, setModal] = useState(false)


    return (
        <>
            <FontAwesomeIcon icon={faInfoCircle} onClick={() => setModal(true)} />

            <Modal show={modal} onHide={() => setModal(false)} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>Loja WorkMotor</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {comments}
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <button className="info-button" onClick={() => setModal(false)}>Voltar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default memo(Info)