import React from 'react'
import { withRouter } from 'react-router-dom'
import './styles.css'
import { Link } from 'react-router-dom'

import boxReinstalacoes from '../../../../assets/img/box-reinstalacoes.png';
import boxRelatorios from '../../../../assets/img/box-relatorios.png';
import boxInstalacoes from '../../../../assets/img/box-instalacoes.png';

function Categories({ history }) {
  return(
    <section className='categories-section'>
      <div className='categories-section-div'>
        <h1>Categorias</h1>
        <p>Escolha abaixo a categoria desejada ou clique em ver todas as categorias</p>
        <center>
          <div className='grid-categories-home'>
            <Link to='/store/services?category=Reinstal' >
              <div name='reinstallation' className='grid-categories-item'>
                <img src={ boxReinstalacoes } alt='Reinstalações' />
              </div>
            </Link>

            <Link to='/store/services?category=Instal' >
              <div name='installation' className='grid-categories-item'>
                <img src={ boxInstalacoes } alt='Instalações' />
              </div>
            </Link>

            <Link to='/store/services?category=Relat' >
              <div name='report' className='grid-categories-item'>
                <img src={ boxRelatorios } alt='Relatórios' /> 
              </div>
            </Link>

            <Link to='/store/services' style={{ display: 'flex', textDecoration: 'unset', color: 'unset' }} >
              <div name='all' className='grid-categories-item' onClick={() => history.push('services')}>
                <div>
                  <h4 style={{ textDecoration: 'none' }}>Ver todas as Categorias</h4>
                </div>
              </div>
            </Link>

          </div>
        </center>
      </div>
    </section>
  )
}

export default withRouter(Categories)