import React from "react";
import './styles.css';
import SubMenu from './SubMenu'

export default function HeaderNav () {
    return (
        <div>
            <div className="principal" />
            <SubMenu/>
        </div>
    );
}