const INITIAL_STATE = {
    search: '',
    categories: [],
    categoriesFilterd: [],
    subCategories: [],
    online: false,
    presencial: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'CHANGE_VALUE':
            return { ...state, [action.payloadType]: action.payload }
        default:
            return { ...state }
    }
}