import React, { Component } from 'react'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'
import { api, config } from '../../../../services/api';

class DetailsSale extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        this.loadItemVenda();
    }

    loadItemVenda = async () => {
        try {
            const itens = await api.get(config.endpoint + `salesitems?SaleId=${this.props.SaleId}`);
            this.setState({ data: itens.data })
        }
        catch (err) {
            console.log(err)
        }
    }

    render() {
        const { data } = this.state
        return (
            <div>
                <ReactTable
                    style={{ width: '100%', textAlign: 'center' }}
                    data={data}
                    onClick={() => this.setState({ filtered: [] })}
                    columns={[
                        {
                            Header: "Item",
                            id: "id",
                            accessor: props => Number(props.id), // FUNÇÃO PARA SETAR EM ORDEM NUMÉRICA
                            headerClassName: "text-left",
                            width: 84,
                            Cell: props =><span>{props.index +1}</span> ,

                        },
                        {
                            Header: "Serviço",
                            accessor: "Services.Description",
                            headerClassName: "text-left",
                            width: 300
                        },
                        {
                            Header: "Valor Unitário",
                            accessor: "Price",
                            headerClassName: "text-left",
                            Cell: props => <span>R$ {parseFloat(props.value).toFixed(2).replace('.', ',')}</span>,
                        },
                        {
                            Header: "Quantidade",
                            accessor: "Quantity",
                            headerClassName: "text-left",
                            width: 120,
                            Cell: props => <span>{props.value || "---"}</span>,

                        },
                        {
                            Header: "Valor Total",
                            accessor: "Amount",
                            headerClassName: "text-left",
                            width: 100,
                            Cell: props => <span>R$ {parseFloat(props.value).toFixed(2).replace('.', ',')}</span>,
                        }
                    ]}
                    defaultPageSize={5}
                    className=""
                    loading={this.state.loading}
                    showPagination={true}
                    sortable={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    defaultSorted={[
                        {
                            id: "id",
                            desc: false
                        }
                    ]}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando..."
                    noDataText="Não há informação"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '3%', fontSize: '18px' }}>
                    <span>Total: R$ {parseFloat(this.props.amount).toFixed(2).replace('.', ',')}</span>
                </div>
            </div>
        )
    }
}

export default DetailsSale;
