import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Logo from "../../../../assets/img/logo-3.png";
import { faUser, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { api, config } from '../../../../services/api';
import { validadeEmail, isAdminLogged } from '../../../../validation'
import { setCookie } from '../../../../cookies'


export default function AdminLogin() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)
  const [user, setUser] = useState({})
  const [disabled, setDisabled] = useState(true)

  const history = useHistory()

  useEffect(() => {
    if (isAdminLogged()) {
      history.push('/admin/home')
    }
  })

  useEffect(() => {
    
    if(validadeEmail(email)) {
      const getUser = async () => {

        try {
  
          const res = await api.get(config.endpoint + `administrator/by-email/${email}` )
          setUser(res.data)
  
        } catch(err) {
          setUser({ })
        }

      }
      getUser()
    }
    else if(user.Email) {
      setUser({ })
    }
    else if (user === '') setUser({ })
  }, [email, user])

  
  useEffect(()=> {

    if (user.Email && password === user.Password) {
      
      setDisabled(false)

    } else if (disabled) setDisabled(true)
    
  }, [password, user.Email, user.Password, disabled])

  const submit = () => {
    setCookie('ADMIN_ID', user.id)
    setCookie('ADMIN_NAME', 'Admin')
    history.push('/admin/home')
  }

  return(
    <div className='Login-container'>
      <div className='Login'>
        <div className="Login-card">
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
            <img className="logo-img" src={Logo} alt="Loja WorkMotor" />
          </div>

          <form className="Login-form" onSubmit={submit}>
            <div className="Login-field">
              <div className="Login-icon">
                <FontAwesomeIcon
                    icon={faUser}
                    aria-hidden="false"
                />
              </div>
              <input 
                type="email"
                value={email}
                onChange={ e => setEmail(e.target.value) }
                onAbort={() => console.log('aqui')}
              />
            </div>
            <span style={{ marginTop: 5, color: 'red', fontSize: 13, display: (user.Email || email === '')  && 'none' }}>E-mail não cadastrado.</span>

            <div className="Login-field">
              <div className="Login-icon">
                <FontAwesomeIcon
                    icon={faLock}
                    aria-hidden="false"
                />
              </div>
              <input
                type={show ? 'text' : 'password'} 
                autoComplete="new-password"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
              <div className="Login-icon-left" onClick={() => setShow(!show)}>
                <FontAwesomeIcon
                  icon={show ? faEye : faEyeSlash}
                  aria-hidden="false"
                />
              </div>
            </div>
            <span style={{ marginTop: 5, color: 'red', fontSize: 13, display: (user.Password === password || password === '' || !user.Email) && 'none' }}>Senha inválida</span>

            <div
              style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  alignItems: "center"
              }}>
              <button className='btn btn-sucesso' type="submit" disabled={disabled}>Entrar</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}
