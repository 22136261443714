import React from 'react'
import { Col } from 'react-bootstrap'
import '../styles.css'

export default function ProgressBarComponent(props) {
    return (
        <div id='progressBar'>
            <Col xs={12} sm={12} md={12} lg={12} className='col-progress2'>
                <Col xs={6} sm={6} md={6} lg={6} className='col-progress' onClick={props.stepProgress === 1 ? () => { } : props.previousPage}>
                    <h5 className={props.percent === 1 ? 'titulo-progress' : 'titulo-progress-desativo'}>1 - Itens</h5>
                    <hr className={props.percent === 1 ? 'linha-ativa' : 'linha-desativa'} />
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className='col-progress' onClick={props.stepProgress === 2 ? () => { } : props.cart ? props.nextPage : () => { }}>
                    <h5 className={props.percent === 2 ? 'titulo-progress' : 'titulo-progress-desativo'}>2 - Pagamento</h5>
                    <hr className={props.percent === 2 ? 'linha-ativa' : 'linha-desativa'} />
                </Col>
            </Col>
        </div>

    )
}