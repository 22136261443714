import { createStore, combineReducers, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import promise from "redux-promise";
import multi from "redux-multi";
import thunk from "redux-thunk";
import cartReducer from "./desktop/store/reduxCart/cartReducer";

import listReducer from './desktop/store/pages/Services/redux/listReducer'

const rootReducer = combineReducers({
  toastr: toastrReducer,
  form: formReducer,
  cartReducer: cartReducer,
  listReducer: listReducer
});

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = applyMiddleware(thunk, multi, promise)(createStore)(
  rootReducer,
  devTools
);

export default store;
