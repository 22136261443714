import React, { memo, useState } from 'react'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import '../styles.css'
import { formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { decrementItemCart, incrementItemCart, removeItemCart, handleChange, handleQuantity } from '../../../reduxCart/cartAction'
import { connect } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'

import  boxReinstalacoes from '../../../../../assets/img/box-reinstalacoes.png';
import  boxRelatorios from '../../../../../assets/img/box-relatorios.png';
import  boxTreinamentos from '../../../../../assets/img/box-treinamentos.png';
import  boxConfiguracoes from '../../../../../assets/img/box-configuracoes.png';
import  boxInstalacoes from '../../../../../assets/img/box-instalacoes.png';
import  boxMaisLucro from '../../../../../assets/img/box-mais-lucro.png';
import  boxAtendimentoAdvanced from '../../../../../assets/img/box-atendimento-advanced.png';

function renderCategoryImg(Category) {
    switch (Category) {
        case "Reinstalações":
            return boxReinstalacoes;
        case "RelatóriosImpressos":
            return boxRelatorios
        case "Treinamentos":
            return boxTreinamentos;
        case "Conversões/Edições":
            return boxConfiguracoes;
        case "Instalações/Contratações":
            return boxInstalacoes;
        case "+Lucro":
            return boxMaisLucro;
        case "Planos":
            return boxAtendimentoAdvanced;
        default:
            return '';
    }
}

const List = (props) => {
    const [modal, setModal] = useState(false)
    const [row, setRow] = useState()
    const [message, setMessage] = useState()

    const showModalExcluir = async (index, name) => {
        setRow(index)
        setMessage('Deseja realmente excluir o item ' + name)
        setModal(true)
    }

    const excluirItem = async () => {
        dispatch(removeItemCart(arrayServices[row], arrayServices))
        setModal(false)
    }

    const { cartReducer: { arrayServices } } = useSelector(state => state);
    const dispatch = useDispatch();

    function handleChange(value, index) {
        if (value <= 0) return
        dispatch(handleQuantity(value, 'Quantity', arrayServices[index], arrayServices))
    }

    return (
        <>
            {arrayServices.length ? arrayServices.map((l, index) => (
                <div key={l.ServiceId} className="list-carrinho">
                    <Col xs={12} sm={12} md={12} lg={12} style={{ display: 'flex' }}>
                        <Col xs={6} sm={6} md={6} lg={6} style={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ height: '100px' }} src={renderCategoryImg(l.Categories.Name)} alt={`${l.description}`} />
                            <br />
                            <div id='detalhes-produto'>
                                <span>{l.Description}</span>
                                <p>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(l.Price)}</p>
                            </div>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} className='col-qtd'>
                            <div>
                                <FontAwesomeIcon id='button-qtd' icon={faMinusCircle} aria-hidden="false" onClick={() => dispatch(decrementItemCart(arrayServices[index], arrayServices))} />
                            </div>
                            <input style={{ width: '12%' }} type='number' min='1' value={l.Quantity} className='form-control foco-input' onChange={(evt) => handleChange(evt.target.value, index)}></input>
                            <div>
                                <FontAwesomeIcon id='button-qtd' icon={faPlusCircle} aria-hidden="false" onClick={() => dispatch(incrementItemCart(arrayServices[index], arrayServices))} />
                            </div>
                            <div>
                                <FontAwesomeIcon id='button-trash' icon={faTrash} aria-hidden="false" onClick={() => showModalExcluir(index, l.Description)} />
                            </div>
                        </Col>
                    </Col>
                </div>
            ))
                :
                <div>
                    <h3>Seu carrinho está vazio</h3>
                    <button
                        className="btn btn-success"
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                        onClick={() => {
                            props.history.push('/store/services')
                            dispatch({
                                type: 'CHANGE_VALUE',
                                payload: '',
                                payloadType: 'search'
                            })
                        }}
                    >Ver Produtos</button>
                </div>
            }

            <Modal show={modal} onHide={() => setModal(false)} animation={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <strong>Loja WorkMotor</strong>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <button className='btn btn-danger' name="Não" onClick={() => setModal(false)}>Não</button>
                        <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={() => excluirItem()}>Sim</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const selector = formValueSelector('List')

const mapStateToProps = state => ({
    cartReducer: state.cartReducer,
    arrayServices: selector(state, 'arrayServices'),
})

const mapDispatchToProps = dispatch => bindActionCreators({ incrementItemCart, handleChange }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(memo(List))