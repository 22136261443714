// import produce from "immer";
const INITIAL_STATE = {
    step: 1,
    arrayServices: [],
    amount: 0,
    type: 'credit-card',
    confirmModal: false,
    Quantidade: 0,
    Quantity: 0,
    loading: false,
    saleId: '',
    creditCard: {
        cardNumber: '',
        brand: '',
        cvv: '',
        month: '',
        year: '',
        isValid: false,
        focus: ''
    },

    // CREDIT CARD
    cardNumber: '',
    brand: '',
    cvc: '',
    month: '',
    yeard: '',
    isValid: false,
    bankSlip: {
        billingDay: '',
        installments: '1'
    },
    digest: { items: [], sale: {} }
}

const RESET_TO_DIGEST = {
    step: 1,
    arrayServices: [],
    amount: 0,
    type: 'credit-card',
    confirmModal: false,
    Quantidade: 0,
    Quantity: 0,
    loading: false,
    saleId: '',
    creditCard: {
        cardNumber: '',
        brand: '',
        cvv: '',
        month: '',
        year: '',
        isValid: false,
        focus: ''
    },

    // CREDIT CARD
    cardNumber: '',
    brand: '',
    cvc: '',
    month: '',
    yeard: '',
    isValid: false,
    bankSlip: {
        billingDay: '',
        installments: '1'
    }
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'HANDLE_CHANGE':
            return { ...state, [action.payloadType]: action.payload }
        case 'HANDLE_CART':
            return { ...state, arrayServices: action.payload, amount: action.payloadAmount, 'bankSlip': { ...INITIAL_STATE.bankSlip } }
        case 'DECREMENT_CART':
            return { ...state, arrayServices: action.payload, amount: action.payloadAmount, 'bankSlip': { ...INITIAL_STATE.bankSlip } }
        case 'INCREMENT_CART':
            return { ...state, arrayServices: action.payload, amount: action.payloadAmount, 'bankSlip': { ...INITIAL_STATE.bankSlip } }
        case 'REMOVE_CART':
            return { ...state, arrayServices: action.payload, amount: action.payloadAmount, 'bankSlip': { ...INITIAL_STATE.bankSlip } }
        case 'CREATE_SALE_STARTED':
            return { ...state, loading: true, confirmModal: false }
        case 'CREATE_SALE_SUCCESS':
            return { ...state, loading: false, digest: action.payload, ...RESET_TO_DIGEST }
        case 'CREATE_SALE_FAILURE':
            return { ...state, loading: false }
        case 'QUANTITY_CART':
            return { ...state, [action.payloadType]: action.payload, arrayServices: action.arrayServices, amount: action.payloadAmount, 'bankSlip': { ...INITIAL_STATE.bankSlip } }
        default:
            return state
    }
}