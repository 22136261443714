import React, { useState, useEffect } from 'react'
import ClickOutside from './ClickOutSide';
import { faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import { eraseCookie, getCookie } from '../../../../cookies'


export default function SubMenu(){
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  
  const logout = async () => {
    await eraseCookie('ADMIN_ID')
    await eraseCookie('ADMIN_NAME')
    window.location.pathname='/admin-login'
  }

  useEffect(() => {
    setName(getCookie('ADMIN_NAME'))
  }, [])

  return(
    <div>
      <ClickOutside onClickOutside={() => { setOpen(false) }}>
        <div className="perfilbtn" onMouseEnter={() => setOpen(true)} onClick={() => setOpen(true)}>
          <FontAwesomeIcon 
            icon={faUserCircle} 
            color='white' 
            aria-hidden="false" 
          />
            <span style={{ textDecoration: 'underline', color: 'white', marginLeft: '2%' }}>
              {name}
            </span>
        </div>
        {open && 
          <div className="submenu-items" onMouseLeave={() => setOpen(false)}>
            <span onClick={logout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                Sair
            </span>
          </div>
        }
      </ClickOutside>

    </div>
  )
}