import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import BreadCrumb from '../../../components/BreadCrumb'
import { Col } from "react-bootstrap";
import Toggle from "react-toggle";
import { api, config } from '../../../../../services/api'
import CurrencyInput from 'react-currency-input';
import { toastr } from 'react-redux-toastr'
import { Collapse, Modal } from 'react-bootstrap'
import { description } from '../../../../../normalize';

import "react-toggle/style.css"
import './styles.css'

class NovoServico extends Component {
    state = {
        allCategories: [],
        allSubCategories: [],
        status: true,
        codigo: "",
        descricao: "",
        preco: 0,
        observacao: "",
        categories: 0,
        subCategories: 0,
        counter: 500,
        cor: 'black',
        modal: false,
        serviceId: 0,
        collapseService: true
    }

    componentDidMount = async () => {
        this.getCategories()
        this.getSubCategories()
        if (this.props.match.params.id) {
            await this.setState({ serviceId: this.props.match.params.id })
            this.getService(this.state.serviceId)
        } else {
            this.getLastId()
        }

    }

    getLastId = async () => {
        try {
            const lastCode = await api.get(config.endpoint + 'services-lastcode')
            let last = lastCode.data.lastcode + 1
            this.setState({ codigo: last })
        } catch (error) {
            return
        }
    }

    getService = async (serviceId) => {
        try {
            const resService = await api.get(config.endpoint + `services/byid?Service_id=${serviceId}`)

            this.setState({
                codigo: resService.data.service.id,
                descricao: resService.data.service.Description,
                status: resService.data.service.Active,
                categories: resService.data.service.CategoryId,
                subCategories: resService.data.service.SubCategoryId,
                preco: resService.data.service.Price.replace('.', ','),
                observacao: resService.data.service.Comments,
            })
        } catch (error) {
            return toastr.error('Não foi possível carregar serviço.')
        }
    }

    getCategories = async () => {
        try {
            const categoria = await api.get(config.endpoint + 'categories')
            this.setState({ allCategories: categoria.data })
        } catch (err) {
            this.setState({ allCategories: [] })
        }
    }

    getSubCategories = async () => {
        try {
            const subcategoria = await api.get(config.endpoint + 'subcategories')
            this.setState({ allSubCategories: subcategoria.data })
        } catch (err) {
            this.setState({ allSubCategories: [] })

        }
    }

    handleChange = async (type, evt) => {
        if (type === 'descricao') {
            const value = description(evt.target.value)
            this.setState({ [type]: value });
            return
        }
        if (type === "categories" && evt.target.value !== "7") {
            this.setState({ subCategories: 0 })
        }
        this.setState({ [type]: evt.target.value });

    }

    handleClickToggleActive(type, event) {
        let isChecked = event.target.checked;
        this.setState({ [type]: isChecked });
    }

    salvarServico = async () => {
        const { descricao, status, categories, subCategories, preco, observacao } = this.state
        let description = descricao.trim()
        if (descricao === "" || status === ""
            || categories === 0 || preco === 0) {
            return toastr.warning('Preencha todos os campos para salvar.')
        }
        if (categories === "7" && subCategories === 0) {
            return toastr.warning('Preencha todos os campos para salvar.')
        }
        const resService = await api.post(config.endpoint + `services-description`, {
            description
        })
        if (resService.data.service.length > 0) {
            for (let i = 0; i < resService.data.service.length; i++) {
                if (resService.data.service[i].Description === description && resService.data.service[i].CategoryId.toString() === categories) {
                    return toastr.warning('Já existe um serviço com essas informações. Por favor, verifique.')
                }
            }
        }
        try {
            await api.post(config.endpoint + 'services-create', {
                Description: description,
                Active: status,
                CategoryId: categories,
                SubCategoryId: subCategories === 0 ? null : subCategories,
                Price: preco.replace('R$', '').replace(',', '.'),
                Comments: observacao
            })
            this.props.history.push('services')

            return toastr.success('Salvo com sucesso.')
        } catch (error) {
            return toastr.error('Não foi possível salvar serviço.')
        }
    }

    updateServico = async () => {
        const { descricao, status, categories, subCategories, preco, observacao } = this.state
        let description = descricao.trim()

        if (description === "" || status === ""
            || categories === 0 || preco === 0) {
            return toastr.warning('Preencha todos os campos para salvar.')
        }
        if (categories === "7" && subCategories === 0 || categories === "7" && subCategories === null) {
            return toastr.warning('Preencha todos os campos para salvar.')
        }
        const resService = await api.post(config.endpoint + `services-description`, {
            description
        })
        if (resService.data.service.length > 0) {
            for (let i = 0; i < resService.data.service.length; i++) {

                if (resService.data.service[0].Description === description &&
                    resService.data.service[0].CategoryId === categories &&
                    resService.data.service[0].id.toString() !== this.state.serviceId) {
                    return toastr.warning('Já existe um serviço com essas informações. Por favor, verifique.')
                }
            }
        }
        try {
            await api.put(config.endpoint + `services-update?Service_id=${this.state.serviceId}`, {
                Description: description,
                Active: status,
                CategoryId: categories,
                SubCategoryId: subCategories,
                Price: preco.replace('R$', '').replace(',', '.'),
                Comments: observacao
            })
            this.props.history.push('services')

            return toastr.success('Atualizado com sucesso.')
        } catch{
            return toastr.error('Não foi possível atualizar serviço.')
        }
    }

    render() {
        this.state.counter = 500 - this.state.observacao.length;
        if (this.state.counter > 50) {
            this.state.cor = 'black'
        } else {
            this.state.cor = 'red'
        }
        return (
            <div>
                <BreadCrumb
                    data={["Início", "Serviços", this.props.match.params.id ? 'Editar Serviço' : 'Novo Serviço']}
                    path={["home", "services", null]}
                />
                <form
                    className=" collapse-container"
                    style={{ marginTop: "2%" }}
                >
                    <button type="button" className="collpase-header" onClick={() => this.setState({ collapseService: !this.state.collapseService })}>
                        {this.state.collapseService === true ? <span id="span-tag" className="fa fa-angle-down" aria-hidden="true" /> :
                            <span id="span-tag" className="fa fa-angle-right" aria-hidden="true" />}
                        <span className="collpase-title">Serviços</span>
                    </button>
                    <Collapse in={this.state.collapseService}>
                        <div className="collapse-body">
                            <Col xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                <Col xs={12} sm={12} md={4} lg={4}>
                                    <label id="label">
                                        Categoria<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select className="form-control foco-input"
                                        value={this.state.categories}
                                        onChange={(evt) => this.handleChange("categories", evt)}
                                    >
                                        <option value="">Selecione</option>
                                        {
                                            this.state.allCategories.map(c => {
                                                return (
                                                    <option name={c.Name} key={c.id} value={c.id}>{c.Name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4}>
                                    <label id="label">
                                        Status<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <div>
                                        <Toggle
                                            checked={this.state.status}
                                            className="custom-toggle"
                                            name="ativo"
                                            style={{ position: 'fixed', backgroundColor: 'darkblue' }}
                                            onClick={(event) => this.handleClickToggleActive('status', event)} />
                                    </div>
                                </Col>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                <Col xs={12} sm={12} md={2} lg={2}>
                                    <label id="label">
                                        Código
                                    </label>
                                    <input
                                        className="form-control foco-input"
                                        value={this.state.codigo}
                                        disabled={true}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4}>
                                    <label id="label">
                                        Descrição<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                        className="form-control foco-input"
                                        value={this.state.descricao}
                                        onChange={(evt) => this.handleChange("descricao", evt)}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2}>
                                    <label id="label">
                                        Preço<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <CurrencyInput
                                        prefix='R$' className="form-control foco-input"
                                        decimalSeparator=","
                                        thousandSeparator="."
                                        selectAllOnFocus
                                        name="preco"
                                        onChangeEvent={(evt) => this.handleChange("preco", evt)}
                                        value={this.state.preco}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <label id="label">
                                        Sub-Categoria<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                        disabled={this.state.categories.toString() === "7" ? false : true}
                                        className="form-control foco-input"
                                        value={this.state.subCategories}
                                        onChange={(evt) => this.handleChange("subCategories", evt)}
                                    >
                                        <option value="">Selecione</option>
                                        {
                                            this.state.allSubCategories.map(s => {
                                                return (
                                                    <option name={s.Name} key={s.id} value={s.id}>{s.Name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </Col>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <label id="label">
                                        Observações (Máximo 500 caracteres)
                                </label>
                                    <textarea
                                        onChange={(evt) => this.handleChange("observacao", evt)}
                                        value={this.state.observacao}
                                        className='form-control'
                                        maxLength='500'
                                    />
                                    <span style={{ color: this.state.cor }}>{this.state.counter} Caracteres restantes</span>

                                </Col>
                            </Col>
                        </div>
                    </Collapse>
                </form>

                <div id="div-btn-footer">
                    <button id="btn-cancelar" onClick={() => this.setState({ modal: true })}>Cancelar</button>
                    <button id="btn-salvar" onClick={() => { this.state.serviceId === 0 ? this.salvarServico() : this.updateServico() }}>
                        {
                            this.state.serviceId === 0 ?
                                "Salvar"
                                :
                                "Atualizar"
                        }
                    </button>
                </div>

                <Modal show={this.state.modal} onHide={() => this.setState({ modal: false })} animation={true}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <strong>Loja WorkMotor</strong>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Deseja realmente cancelar o cadastro do serviço? Esta ação não salvará as informações apresentadas na tela.
                    </Modal.Body>
                    <Modal.Footer>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <button className='btn btn-danger' name="Não" onClick={() => this.setState({ modal: false })}>Não</button>
                            <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={() => this.props.history.push('services')}>Sim</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}

export default withRouter(NovoServico);