import React, { useState } from "react";
import HeaderNav from '../HeaderNav'
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { faHome, faClipboardList, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './SideNav.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

export default function Menu({ expanded, onExpand, location, history }){
    const [path, setPath] = useState('')

    return (
        <div >
            <HeaderNav />
            <SideNav 
                style={{ position: 'fixed' }}
                expanded={expanded}
                onSelect={(selected) => {
                    const to = '/' + selected;
                    if (location.pathname !== to && selected !== 'fixed') {
                        history.push(to);
                        setPath(selected)
                    }
                }}
            >
                <SideNav.Nav defaultSelected={'admin/home'}>
                    <NavItem eventKey='fixed'>
                        <NavIcon>
                            <SideNav.Toggle id="side-toggle" onClick={() => onExpand(!expanded)}/>
                        </NavIcon>
                    </NavItem> 

                    <NavItem eventKey="admin/home" title="Visão Geral" active={path === 'admin/home'} >
                        <NavIcon >
                            <FontAwesomeIcon icon={faHome} color='blue' className="iconeMenu" style={{ backgroundColor: 'yellow !important' }} aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Visão Geral
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/services" title="Serviços" active={path === 'admin/services'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faClipboardList} className="iconeMenu" aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Serviços
                        </NavText>
                    </NavItem>

                    <NavItem eventKey="admin/sales"  title="Vendas" active={path === 'admin/sales'}>
                        <NavIcon>
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="iconeMenu" aria-hidden="false" />
                        </NavIcon>
                        <NavText style={{ color: 'white' }}>
                            Vendas
                        </NavText>
                    </NavItem>

                </SideNav.Nav>
            </SideNav>
        </div>
    )
}
