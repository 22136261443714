import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import routes from '../admin/routes'
import { isAdminLogged } from '../../validation'
import Menu from '../admin/components/Menu'
import MainCard from '../admin/components/MainCard'

function AdminLayout(props) {
    const [expanded, setExpanded] = useState(false)

    function getRoutes(routes) {
        return routes.map((prop, key) => (
            <Route
                exact
                path={prop.layout + prop.path}
                render={props => <prop.component {...props} />}
                key={key}
            />
        ));
    };

    useEffect(() => {
        if (!isAdminLogged()) window.location.href='/admin-login'
    }, [])

    return (
        <>
        {isAdminLogged() &&
        <>
            <Menu expanded={expanded} onExpand={e => setExpanded(e)} {...props} />
            <MainCard className='main-card-content-margin main-card-content' style={{ marginLeft: expanded ? 250 : 85 }}>
                <Switch>{getRoutes(routes)}</Switch>
            </MainCard>
        </>
        }
        </>
    )
}

export default AdminLayout