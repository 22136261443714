import Main from './pages/Main';
import Services from './pages/Services'
import Sales from './pages/Sales'
import NovoServico from './pages/Services/NovoServico/NovoServico.jsx'

const dashboardRoutes = [
    {
        path: "/home",
        name: "Página Inicial",
        component: Main,
        layout: "/admin"
    }, 
    {
        path: "/services",
        name: "Serviços",
        component: Services,
        layout: "/admin"
    }, 
    {
        path: "/sales",
        name: "Vendas",
        component: Sales,
        layout: "/admin"
    },
    {
        path: "/novo-servico",
        name: "Servico",
        component: NovoServico,
        layout: "/admin"
    }, 
    {
        path: "/EditarServico#:id",
        name: "Editar Serviço",
        component: NovoServico,
        layout: "/admin"
    },
];

export default dashboardRoutes;