import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Carousel from "./Carousel";
import Categories from "./Categories";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./styles.css";

import { api, config } from "../../../../services/api";
import { withRouter } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addCart } from "../../reduxCart/cartAction";

function Main({ history }) {
  const [carouselItems, setCarouselItems] = useState([]);

  const {
    cartReducer: { arrayServices },
  } = useSelector((state) => state);
  
  const dispatch = useDispatch();

  useEffect(() => {
    getTopFiveServices();
  }, []);

  const getTopFiveServices = async () => {
    const res = await api.get(config.endpoint + "services/top-five-services");
    setCarouselItems(res?.data || []);
  };

  return (
    <div className="container-home">
      <Header />
      {/* TO DO */}
      <Carousel
        items={carouselItems}
        onClick={(i) => {
          dispatch([
            addCart(i, arrayServices),
            {
              type: "HANDLE_CHANGE",
              payload: 1,
              payloadType: "step",
            },
          ]);
          history.push("/store/items");
        }}
      />
      <Categories />
      <footer>
        <center>
          <Link to="/admin-login">Área Admin</Link>
          <h6>
            <FontAwesomeIcon icon={faCopyright} aria-hidden="false" /> WorkGroup
            2020
          </h6>
        </center>
      </footer>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cartReducer: state.cartReducer,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ addCart }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
