import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'
import DashBoardCard from './DashBoardCard'
import './styles.css'
import { faClipboardList, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { api, config} from '../../../../services/api'
import { toastr } from 'react-redux-toastr'

export default function Main({ history }){
  const [counters, setCounters] = useState({})

  useEffect(() => {
    const getCounters = async() => {
      try{

        const res = await api.get(config.endpoint + 'administrator/counters')
        setCounters(res.data)

      } catch(err) {
        console.log(err);
        toastr.error('Ocorreu um erro ao buscar os contadores')
      }
    }
    getCounters()
  },  [])

  return(
    <div>
      <BreadCrumb data={['Início']} path={[null]}/>
      <br/>
      <div className='admin-main-content-cards'>
        <DashBoardCard 
          onClick={() => history.push('services')}
          title='Serviços'
          counter={counters.activeServices}
          icon={faClipboardList}
          circleColor='#3f51b5'
        />

        <DashBoardCard 
          onClick={() => history.push('sales')}
          title='Vendas'
          counter={counters.sales}
          icon={faFileInvoiceDollar}
          circleColor='#ffa834'
        />
      </div>
    </div>
  )
}