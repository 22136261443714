import React from 'react'
import SelectInstallments from './SelectInstallments'
import { useDispatch, useSelector } from 'react-redux'
import { handleBankSlip } from '../../../../reduxCart/cartAction'


export default function BankSlip({ installments, total }) {
  const { cartReducer: { type, bankSlip } } = useSelector(state => state);
  const { billingDay } = bankSlip
  const dispatch = useDispatch();

  return(
    <section>
      <div >
        <input readOnly id='bank-slip' name='radio-form-payments' type='radio' checked={type === 'bank-slip'}/>
        <label htmlFor='bank-slip'><strong>Boleto</strong></label>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <label htmlFor='select-installments'>Parcelamento</label>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <SelectInstallments total={total} />
          </div>
        </div>

        <div>
          <label htmlFor='select-billing-day'>Vencimento</label>
          <select 
            id='select-billing-day' 
            className='form-control foco-input' 
            value={billingDay} 
            onChange={(e) => dispatch(handleBankSlip(bankSlip, e.target.value, 'billingDay'))}>
            <option value=''>Selecione</option>
            <option value={2}>02</option>
            <option value={14}>14</option>
          </select>
        </div>

        
      </div>
    </section>
  )
}