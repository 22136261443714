import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../store/routes";
import Header from "../store/components/header";

function StoreLayout() {
  function getRoutes(routes) {
    return routes.map((prop, key) => (
      <Route
        exact
        path={prop.layout + prop.path}
        render={(props) => <prop.component {...props} />}
        key={key}
      />
    ));
  }

  return (
    <>
      <Header />
      <div className="content">
        <Switch>{getRoutes(routes)}</Switch>
      </div>
    </>
  );
}

export default StoreLayout;
