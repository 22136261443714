import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from 'react-table-6'
import orderBy from "lodash/orderBy";
import { cpfMask, cnpjMask, currency } from '../../../../normalize'
import DetailsSale from "./DetailsSale"

export default function SalesTable({ query, filterCompany, data, loading }) {
  const [detailsModal, setDetailsModal] = useState(false)
  const [SaleId, setSaleId] = useState('')
  const [amount, setAmount] = useState('')

  const showModal = async (sale) => {
    setSaleId(sale.id)
    setAmount(sale.Amount)
    setDetailsModal(true)
  }

  return (
    <div>
      <ReactTable
        className='react-table-sales'
        data={orderBy(query ?
          data.filter(x =>
            x['Code']?.toString().includes(query) ||
            x['CpfCnpf'].toString().includes(query)
          )
          :
          filterCompany ?
          data.filter(x => x['NameCompany'].toString().toLowerCase().includes(filterCompany.toLowerCase()) )
          :
          data
        )}
        columns={[
          {
            Header: "N. Venda",
            accessor: "Code",
            width: 80
          },
          {
            Header: "Data",
            id: "Date",
            accessor: 'Date',
            Cell: props => new Date(props.value).toLocaleDateString(),
            width: 100
          },
          {
            Header: "CNPJ",
            accessor: "CpfCnpf",
            Cell: props => props.value.length > 11 ? cnpjMask(props.value) : cpfMask(props.value),
            width: 145
          },
          {
            Header: "Razão Social",
            accessor: "NameCompany",
          },
          {
            Header: "Valor",
            accessor: "Amount",
            Cell: props => currency(props.value),
            width: 100
          },
          {
            Header: "Forma de Pagamento",
            accessor: "Type",
            width: 200,
            Cell: props => <div style={{ cursor: 'default', borderRadius: 5, fontSize: 15 }} className={` ${props.value === 'Boleto Bancário' ? 'btn-danger' : 'btn-success'}`}>{props.value}</div>
          },
          {
            Header: "Condição",
            accessor: "NumberOfInstallments",
            width: 80,
            Cell: props => <span>{props.value}x</span>
          },
          {
            Header: "Detalhes",
            accessor: "id",
            Cell: props => <FontAwesomeIcon cursor='pointer' icon={faWindowRestore} onClick={() => showModal(props.original)} />,
            width: 80
          },
        ]}
        defaultPageSize={10}
        loading={loading}
        showPagination={true}
        sortable={true}
        showPaginationTop={false}
        showPaginationBottom={true}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        defaultSorted={[
          {
            id: "Date",
            desc: true
          }
        ]}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Não há informação"
        pageText="Página"
        ofText="de"
        rowsText="linhas"
      />

      {/* showDetails={() => setDetailsModal(true)} */}

      <Modal show={detailsModal} onHide={() => setDetailsModal(false)} animation={true} size="lg" >
        <Modal.Header closeButton className="linha-header-modal" />
        <Modal.Body>
          <DetailsSale SaleId={SaleId} amount={amount} />
        </Modal.Body>
        <Modal.Footer className="linha-fotter-modal">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <button className="info-button" onClick={() => setDetailsModal(false)}>Voltar</button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>

  )
}