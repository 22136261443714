import React from 'react'
import MainCard from '../../components/MainCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css'

export default function DashBoardCard({ title, icon, onClick, circleColor, counter }) {
  return(
    <MainCard onClick={onClick} style={{ cursor: 'pointer' }} >
      <div className='dashboard-card' >
        <span>{title}</span>
        <h4>{counter}</h4>
        <div style={{ backgroundColor: circleColor }}>
          <FontAwesomeIcon icon={icon} color='white' aria-hidden="false" />
        </div>
      </div>
    </MainCard>
  )
}