import React from 'react'
import '../../styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { handleChange } from '../../../../reduxCart/cartAction'
import CreditCardPayment from './CreditCard'
import BankSlip from './BankSlip'



export default function Payment({ previousPage, onSubmit }) {
  const { cartReducer: { type, loading, amount } } = useSelector(state => state);
  const dispatch = useDispatch();
  
  return(
    <>
      <section id='card'>
          <div id='card-div'>
              <h3 id='titulo'> Método de Pagamento</h3>
              <hr />
              <div className='container-payment-step'>
                <div
                  className='type-payment' 
                  name='credit-card'
                  style={{ border: `2px solid ${type ==='credit-card' ? '#1666c0' : 'gray'}` }} 
                  onClick={ () => dispatch(handleChange('credit-card', 'type')) }
                >
                  <CreditCardPayment total={amount} />
                </div>
                <br/>
                <div 
                  className='type-payment' 
                  style={{ border: `2px solid ${type ==='bank-slip' ? '#1666c0' : 'gray'}` }} 
                  onClick={ () => dispatch(handleChange('bank-slip', 'type')) }
                >
                  <BankSlip total={amount} />
                </div>
              </div>
          </div>
      </section>
      <div className='payment-footer-buttons'>
        <div>
          <button className='btn btn-danger' disabled={loading} onClick={previousPage} style={{ width: 'inherit', marginRight: '10px' }}>Voltar</button>
          <button className='btn btn-success' disabled={loading}  style={{ width: 'inherit' }} onClick={onSubmit}><span  className={loading ? 'fa fa-spinner fa-pulse fa-1x' : ''} /> Concluir pedido</button>
        </div>
      </div>

      
    </>
  )
}