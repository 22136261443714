import React from "react";
import ItemsList from './ItemsList'
import '../styles.css'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { addCart } from '../../../reduxCart/cartAction'

let ItemsData = (props) => {

    return (
        <div>
            <section id='card'>
                <div id='card-div'>
                    <h3 id='titulo'> Carrinho de Compras</h3>
                    <hr />
                    <ItemsList history={props.history} />
                    <hr />
                    <h5 id='h4-total'>Total: {props.cartReducer.amount ? props.cartReducer.amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : 'R$ 0,00'}</h5>
                </div>
            </section>
            <div id="div-btn">
                <button id="btn-continuar" onClick={() => props.history.push('services')}>Continuar Comprando</button>
                <button id="btn-avancar" onClick={props.cart ? props.nextPage : () => {}} >Avançar</button>
            </div>
        </div>
    )
}

const selector = formValueSelector('ItemsData')

const mapStateToProps = state => ({
    cartReducer: state.cartReducer,
    arrayServices: selector(state, 'arrayServices'),
})

const mapDispatchToProps = dispatch => bindActionCreators({ addCart }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ItemsData);
