import axios from "axios";

export const config = {
  // Production (VPS Pagtos)
  //endpoint: "https://api.lojaworkmotor.com.br/",

  // Homologação (VPS Carrook)
  endpoint: "https://wkmecommerceapi.carrook.com.br/",

  // Local
  //endpoint: "http://localhost:21270/v1/",
};

export const api = axios.create({ baseURL: config.endpoint });
