import React, { useState, useEffect } from 'react'
import './styles.css'
import { faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoggedMenu from './LoggedMenu'
import DisloggedMenu from './DisloggedMenu'
import { withRouter, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearFilter, changeValue } from '../../pages/Services/redux/listActions'

import img from '../../../../assets/img/logo.png'

function Header({ history }) {
  const [user, setUser] = useState(null)
  const [name, setName] = useState(null)

  const { listReducer: { search, categories }, cartReducer: { arrayServices } } = useSelector(state => state);
  const dispatch = useDispatch();

  const userCnpj = localStorage.getItem('CNPJ')
  const userName = localStorage.getItem('NOME_FANTASIA')


  useEffect(() => {
    setUser(localStorage.getItem('CNPJ'))
    setName(localStorage.getItem('NOME_FANTASIA')?.split(' ')[0])

  }, [userCnpj, userName])


  return (
    <header className='main-header'>
      <Link to='/store' onClick={() =>
        dispatch([
          {
            type: "CHANGE_VALUE",
            payload: '',
            payloadType: 'search'
          },
          clearFilter(categories)
        ])
      }>
        <img src={ img } style={{ width: '200px' }} alt='Workmotor' />
      </Link>
      <form>
        <div>
          <input
            type='text'
            placeholder='Pesquise por produto ou serviço'
            className='form-control foco-input'
            value={search}
            onChange={event => dispatch(
              [
                clearFilter(categories),
                changeValue(event.target.value, 'search')
              ]
            )}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault()
                if (window.location.href.split("store/")[1]) {
                  history.push("services")
                } else {
                  history.push("store/services")
                }
              } else if (event.key === 'Escape') {
                event.preventDefault()
                dispatch({
                  type: "CHANGE_VALUE",
                  payload: '',
                  payloadType: 'search'
                })
              }
            }}
          />
          <FontAwesomeIcon icon={faSearch} style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={() => history.push(window.location.href.split("store/")[1] ? "services" : "store/services")} cursor='pointer' color='gray' aria-hidden="false" />
        </div>
      </form>
      <div className='menu-right'>
        <section>
          {user
            ? <LoggedMenu name={name} />
            : <DisloggedMenu />
          }
          <div style={{ paddingLeft: '10px', display: 'flex' }}>
            <FontAwesomeIcon
              icon={faShoppingCart}
              style={{ height: '100%', width: '25px' }}
              cursor='pointer'
              onClick={() => {
                history.push('/store/items')
                dispatch([
                  changeValue('', 'search'),
                  {
                    type: 'HANDLE_CHANGE',
                    payload: 1,
                    payloadType: 'step'
                  }
                ])
              }}
              color='white'
              aria-hidden="false"
            />
            <span id='badge-itens' style={{ cursor: 'pointer' }} onClick={() => history.push('/store/items')}>{arrayServices.length || 0}</span>
          </div>
        </section>
      </div>



    </header>
  )
}

export default withRouter(Header)