import React from 'react'
import { Carousel } from 'react-bootstrap'
import './styles.css'

import  boxReinstalacoes from '../../../../assets/img/box-reinstalacoes.png';
import  boxRelatorios from '../../../../assets/img/box-relatorios.png';
import  boxTreinamentos from '../../../../assets/img/box-treinamentos.png';
import  boxConfiguracoes from '../../../../assets/img/box-configuracoes.png';
import  boxInstalacoes from '../../../../assets/img/box-instalacoes.png';
import  boxMaisLucro from '../../../../assets/img/box-mais-lucro.png';
import  boxAtendimentoAdvanced from '../../../../assets/img/box-atendimento-advanced.png';

function renderCategoryImg(CategoryId) {
  switch (CategoryId) {
      case 1:
          return boxAtendimentoAdvanced;
      case 2:
          return boxInstalacoes;
      case 3:
          return boxMaisLucro;
      case 4:
          return boxReinstalacoes;
      case 5:
          return boxRelatorios;
      case 6:
          return boxConfiguracoes;
      case 7:
          return boxTreinamentos;
      default:
          return '';
  }
}

export default function CarouselHome({ items, onClick, loading }) {
  return (
    <div className='carousel-home'>
      {
        !items.length ?
          <div className="carousel-home-no-items" />
          :
          <Carousel>
            {items.map(i =>
              <Carousel.Item className='carousel-home-item' key={i.Description}>
                <section>
                  <img src={ renderCategoryImg(i.CategoryId) } alt={i.Description} />
                </section>
                <Carousel.Caption>
                  <h3>{i.Description}</h3>
                  <h3>{Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(i.Price)}</h3>
                  <button className='btn btn-success' type='button' onClick={() => onClick(i)}>COMPRE AGORA</button>
                </Carousel.Caption>
              </Carousel.Item>
            )}
          </Carousel>
      }
    </div>
  )
}