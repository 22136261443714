import React, { useState, useEffect } from 'react'
import BreadCrumb from '../../components/BreadCrumb'

import { api, config } from '../../../../services/api';
import { toastr } from 'react-redux-toastr';
import SalesTable from './SalesTable'
import './styles.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { onlyNumbers } from '../../../../normalize'


export default function Sales() {
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [data, setData] = useState([])
  const [sales, setSales] = useState([])
  const [formOfPayment, setFormofPayment] = useState('')
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  useEffect(() => {
    setLoading(true)
    const getSales = async () => {
      try {

        let resSales = await api.get(config.endpoint + 'sales')
        resSales = resSales.data.map(c => {
          let obj = c

          if (obj.Type === 'Boleto') {
            obj.Type = obj.Type + ' Bancário'
          }

          return obj
        })

        setData(resSales)
        setSales(resSales)

      } catch (err) {
        console.log(err);
        toastr.error('Ocorreu um erro ao buscar as vendas')
      }
    }
    getSales()
    setLoading(false)
  }, [])

  useEffect(() => {
    let init = new Date(initialDate).getTime()
    let final = new Date(finalDate)

    // ADD ONE MORE DAY 
    final = new Date(final.setDate(final.getDate() + 1)).getTime()

    let salesToSet = sales

    /// SET THE FILTER TO FORM OF PAYMENT
    if (formOfPayment !== '') {

      salesToSet = salesToSet.filter(c => c.Type === formOfPayment)
      setData(salesToSet)

    }

    /// VERIFICA AS DATAS INICIAIS E FINAIS PARA FILTRAR
    if (initialDate !== '' && finalDate === '') {

      salesToSet = salesToSet.filter(c => new Date(c.Date).getTime() >= init)

    } else if (initialDate !== '' && finalDate !== '') {

      salesToSet = salesToSet.filter(c => new Date(c.Date).getTime() >= init && new Date(c.Date).getTime() <= final)

    } else if (initialDate === '' && finalDate !== '') {

      salesToSet = salesToSet.filter(c => new Date(c.Date).getTime() <= final)

    }

    setData(salesToSet)

  }, [finalDate, sales, initialDate, formOfPayment])

  return (
    <div>
      <BreadCrumb path={['home', null]} data={['Início', 'Vendas']} />

      <div className='col-xs-12 col-md-12 header-sales-consult'>
        <div className='col-md-3'>
          <input
            className='form-control foco-input'
            title='Pesquisar por Venda, CNPJ ou Razão Social'
            placeholder='Pesquisar por Venda, CNPJ ou Razão Social'
            type='text'
            value={query}
            onChange={e => setQuery(e.target.value)}
          />
          <FontAwesomeIcon icon={faSearch} />
        </div>

        <div className='sales-filter'>
          <label>Forma de Pagamento:</label>
          <select value={formOfPayment} onChange={e => setFormofPayment(e.target.value)} className='form-control foco-input col-md-6'>
            <option value=''>Ambos</option>
            <option value='Boleto Bancário'>Boleto Bancário</option>
            <option value='Cartão de Crédito'>Cartão de Crédito</option>
          </select>

        </div>

        <div className='sales-filter'>
          <label>Data Inicial: </label>
          <input value={initialDate} onChange={e => setInitialDate(e.target.value)} type='date' className='form-control foco-input col-md-8' />

        </div>

        <div className='sales-filter'>

          <label>Data Final: </label>
          <input type='date' value={finalDate} onChange={e => setFinalDate(e.target.value)} className='form-control foco-input col-md-8' />

        </div>
      </div>

      <br />

      <SalesTable loading={loading} query={onlyNumbers(query)} filterCompany={query} data={data} />

    
    </div>
  )
}