import React, { useState } from "react";
import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { formatMoney, forName } from "../../../../normalize";
import { validadeEmail } from "../../../../validation";

import { api, config } from "../../../../services/api";

export default function Digest({ digest }) {
  const [modal, setModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [succMail, setSuccMail] = useState(false);
  const [loading, setLoading] = useState(false);

  async function sendMail() {
    if (!validadeEmail(email))
      return toastr.warning("E-mail inválido", "Por favor, verifique");
    setLoading(true);
    try {
      await api
        .post(config.endpoint + "email/clientMail", {
          SaleId: digest.sale.id,
          name,
          email,
        })
        .then((resp) => {
          toastr.success(resp.data.message);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
      return toastr.warning("Falha ao enviar o E-mail");
    }
    setLoading(false);
    setModal(false);
    setSuccMail(true);
  }

  return (
    <>
      <div className="cart-digest">
        <section>
          <div className="digest-title">
            <span>Compra Confirmada</span>
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <div className="digest-subtitle">
            <p>
              Foi gerado o pedido: <strong>#{digest.sale?.Code}</strong>
            </p>
            {succMail && (
              <span>
                O resumo da compra foi enviado para o e-mail:{" "}
                <strong>{email}</strong>
              </span>
            )}
          </div>
          <table className="table">
            <tr>
              <th>ITEM</th>
              <th align="center">QUANTIDADE</th>
              <th align="center">UNITÁRIO</th>
              <th align="center">TOTAL</th>
            </tr>

            {digest.items.map((item) => (
              <tr key={item.id}>
                <td>{item.Service.Description}</td>
                <td align="center">{item.Quantity}</td>
                <td align="center">{item.Price}</td>
                <td align="center">{item.Amount}</td>
              </tr>
            ))}
          </table>

          <div className="digest-footer">
            <div className="digest-footer-button">
              {!succMail && (
                <button
                  className="btn btn-success"
                  onClick={() => setModal(true)}
                >
                  Enviar Resumo por E-mail
                </button>
              )}
            </div>

            <div>
              <h3>
                <strong>Total: {formatMoney(digest.sale.Amount)}</strong>
              </h3>
              <span>
                {digest.sale.NumberOfInstallments}x em{" "}
                {formatMoney(
                  digest.sale.Amount / digest.sale.NumberOfInstallments
                )}{" "}
                sem juros
              </span>
            </div>
          </div>
        </section>
      </div>

      <Modal show={modal} onHide={() => setModal(false)} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>Loja WorkMotor</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="digest-modal">
            <span>
              Insira seu Nome e E-mail para enviarmos o resumo da venda
            </span>

            <div>
              <label>Nome</label>
              <input
                className="form-control foco-input"
                value={name}
                onChange={(event) => setName(forName(event.target.value))}
              />
            </div>

            <div>
              <label>E-mail</label>
              <input
                className="form-control foco-input"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="btn btn-danger"
              disabled={loading}
              onClick={() => setModal(false)}
              style={{ marginRight: "10px" }}
            >
              Cancelar
            </button>
            <button
              className="btn btn-success"
              disabled={!email || !name}
              style={
                !email || !name || loading ? { cursor: "not-allowed" } : {}
              }
              onClick={() => sendMail()}
            >
              Enviar E-mail{" "}
              {loading && <span className="fa fa-spinner fa-pulse fa-1x" />}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
