import Main from './pages/Main'
import Services from './pages/Services'
import Items from './pages/Items'

const routes = [
    {
        path: "/",
        component: Main,
        layout: "/store"
    },
    {
        path: "/services",
        component: Services,
        layout: "/store"
    },
    {
        path: "/items",
        component: Items,
        layout: "/store"
    }
]

export default routes