import React from 'react'
import { Modal } from 'react-bootstrap'

export default function AlertModal(props){
    return(
        <Modal show={props.show} onHide={()=>props.onHide()} animation={props.animation}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <strong>Loja WorkMotor</strong>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.message}
            </Modal.Body>
            <Modal.Footer>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <button className='btn btn-danger' name="Não" onClick={(e) => props.onCancel(e)}>Não</button>
                    <button className='btn btn-success' name="Sim" style={{ marginLeft: 5 }} onClick={(e)=>props.onSubmit(e)}>Sim</button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}