import { api, config } from '../../../services/api'
import { toastr } from "react-redux-toastr"

export function handleChange(value, type) {
    return {
        type: 'HANDLE_CHANGE',
        payload: value,
        payloadType: type
    }
}

export function addCart(objService, arrayServices) {
    const { Id, id, Description, Price, CategoryId, SubCategoryId, Categories } = objService
    // meu array
    let exist = false
    if (arrayServices.length > 0) {
        arrayServices.map((c, index) => {
            if (c.ServiceId === Id || c.ServiceId === id) {
                arrayServices[index].Quantity++ // Aumentando a quantidade
                arrayServices[index].Amount = arrayServices[index].Price * arrayServices[index].Quantity // Aumentando valor total
                exist = true
            }
        });
    }
    if (!exist) {
        const obj = [
            {
                ServiceId: Id ? Id : id,
                Description,
                Price,
                Quantity: 1,
                Amount: Price * 1,
                CategoryId,
                SubCategoryId,
                Categories
            }
        ]
        arrayServices = [...arrayServices, ...obj]
    }
    let total = arrayServices.map(x => x.Amount);
    total = total
        .reduce((acumulado, valoratual) => acumulado + valoratual)

    return {
        type: 'HANDLE_CART',
        payload: arrayServices,
        payloadAmount: total
    }
}

export function decrementItemCart(objService, arrayServices) {
    arrayServices.map((c, index) => {
        if (c.ServiceId === objService.Id || c.ServiceId === objService.id || c.ServiceId === objService.ServiceId) {
            if (arrayServices[index].Quantity > 1) {
                arrayServices[index].Quantity-- // Aumentando a quantidade
                arrayServices[index].Amount = arrayServices[index].Price * arrayServices[index].Quantity // Aumentando valor total
            }
        }
    });
    let total = arrayServices.map(x => x.Amount);
    total = total
        .reduce((acumulado, valoratual) => acumulado + valoratual)
    return {
        type: 'DECREMENT_CART',
        payload: arrayServices,
        payloadAmount: total
    }
}
export function incrementItemCart(objService, arrayServices) {
    arrayServices.map((c, index) => {
        if (c.ServiceId === objService.Id || c.ServiceId === objService.id || c.ServiceId === objService.ServiceId) {
            arrayServices[index].Quantity++ // Aumentando a quantidade
            arrayServices[index].Amount = arrayServices[index].Price * arrayServices[index].Quantity // Aumentando valor total
        }
    });
    let total = arrayServices.map(x => x.Amount);
    total = total
        .reduce((acumulado, valoratual) => acumulado + valoratual)
    return {
        type: 'INCREMENT_CART',
        payload: arrayServices,
        payloadAmount: total
    }
}

export function handleCreditCard(creditCard, value, type) {
    const obj = { ...creditCard }
    obj[type] = value

    return {
        type: 'HANDLE_CHANGE',
        payload: obj,
        payloadType: 'creditCard'
    }

}

export function handleBankSlip(bankSlip, value, type) {
    const obj = { ...bankSlip }
    obj[type] = value

    return {
        type: 'HANDLE_CHANGE',
        payload: obj,
        payloadType: 'bankSlip'
    }
}

export function createSale(cartReducer) {
    const {
        type,
        arrayServices,
        amount,
        bankSlip: {
            installments,
            billingDay
        },
        creditCard: {
            cardNumber,
            brand,
            month,
            year,
            cvv
        }
    } = cartReducer

    return async function (dispatch) {
        dispatch({ type: 'CREATE_SALE_STARTED' });

        try {
            const res = await api.post(config.endpoint + 'sales/full-sale', {
                Date: new Date(),
                Type: type === 'credit-card' ? 'Cartão de Crédito' : 'Boleto',
                Amount: amount, // TO DO
                Items: arrayServices,
                NumberOfInstallments: installments,
                InstallmentValue: Math.round(amount / installments), // TO DO
                BillingDay: type === 'bank-slip' && billingDay,
                NameCompany: localStorage.getItem('RAZAO_SOCIAL'),
                CpfCnpf: localStorage.getItem('CNPJ'),
                CreditCard: {
                    Amount: amount, // TODO
                    Card_Number: cardNumber,
                    Month_Validity: month,
                    Installments: installments,
                    Year_Validity: year,
                    SecurityCode: cvv,
                    Brand: brand
                }
            })

            dispatch(createdSaleSuccess(res.data))

        } catch (err) {
            dispatch(createSaleFailure(err.response.data))
        }
    }
}

function createdSaleSuccess({ items, sale }) {
    toastr.success('Sucesso')

    return { type: 'CREATE_SALE_SUCCESS', payload: { items, sale } }
}

function createSaleFailure(data) {
    toastr.warning('Ocorreu um erro ao realizar o pagamento', data.message)

    return { type: 'CREATE_SALE_FAILURE' }
}

export function handleQuantity(value, type, objService, arrayServices) {
    arrayServices.map((c, index) => {
        if (c.ServiceId === objService.Id || c.ServiceId === objService.id || c.ServiceId === objService.ServiceId) {
            arrayServices[index].Quantity = value // Aumentando a quantidade
            arrayServices[index].Amount = arrayServices[index].Price * value // Aumentando valor total
        }
    });
    let total = 0;
    if (arrayServices.length > 0) {
        total = arrayServices.map(x => x.Amount);
        total = total.reduce((acumulado, valoratual) => acumulado + valoratual)
    }
    return {
        type: 'QUANTITY_CART',
        payload: value,
        payloadType: type,
        arrayServices: arrayServices,
        payloadAmount: total
    }
}

export function removeItemCart(objService, arrayServices) {
    let filtered = arrayServices.filter(s => {
        if (s.ServiceId !== objService.ServiceId) return s
    })
    let total = 0;

    if (filtered.length > 0) {
        total = filtered.map(x => x.Amount);
        total = total.reduce((acumulado, valoratual) => acumulado + valoratual)
    }
    return {
        type: 'REMOVE_CART',
        payload: filtered,
        payloadAmount: total
    }
}
