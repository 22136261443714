import React, { memo } from 'react'
import './styles.css'
import Info from './Info'
import { addCart } from '../../reduxCart/cartAction'
import { clearFilter, changeValue } from './redux/listActions'
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '../../../../components/Skeleton'

import  boxReinstalacoes from '../../../../assets/img/box-reinstalacoes.png';
import  boxRelatorios from '../../../../assets/img/box-relatorios.png';
import  boxTreinamentos from '../../../../assets/img/box-treinamentos.png';
import  boxConfiguracoes from '../../../../assets/img/box-configuracoes.png';
import  boxInstalacoes from '../../../../assets/img/box-instalacoes.png';
import  boxMaisLucro from '../../../../assets/img/box-mais-lucro.png';
import  boxAtendimentoAdvanced from '../../../../assets/img/box-atendimento-advanced.png';

function renderCategoryImg(Category) {
    switch (Category) {
        case "Reinstalações":
            return boxReinstalacoes;
        case "Relatórios/Impressos":
            return boxRelatorios
        case "Treinamentos":
            return boxTreinamentos;
        case "Conversões/Edições":
            return boxConfiguracoes;
        case "Instalações/Contratações":
            return boxInstalacoes;
        case "+Lucro":
            return boxMaisLucro;
        case "Planos":
            return boxAtendimentoAdvanced;
        default:
            return '';
    }
}

function renderList(list, history, dispatch, arrayServices, categories) {
    return <div className="services-list">
        {list.map(l => (
            <div key={l.id} className="list-item">
                <div className="list-item-info">
                    <Info comments={l.Comments} />
                </div>
                <img src={ renderCategoryImg(l.Categories.Name) } alt={`${l.Description}`} />
                <span>{l.Description}</span>
                <section>
                    <span>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(l.Price)}</span>
                    <button className='btn btn-success' onClick={() => {
                        dispatch([
                            addCart(l, arrayServices),
                            clearFilter(categories),
                            changeValue('', 'search'),
                            {
                                type: 'HANDLE_CHANGE',
                                payload: 1,
                                payloadType: 'step'
                            }
                        ])
                        history.push('/store/items')
                    }}>Comprar</button>
                </section>
            </div>
        ))}
    </div>
}

export function renderSkeleton() {

    const array = new Array(10).fill()

    const SkeletonItem = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '200px',
                height: '360px',
                background: '#fff'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Skeleton
                    style={{
                        width: '150px',
                        height: '180px',
                        marginTop: 10
                    }}
                />
                <Skeleton
                    style={{
                        width: '170px',
                        height: '22px',
                        marginTop: 10
                    }}
                />
            </div>
            <div>
                <Skeleton
                    style={{
                        width: '170px',
                        height: '35px',
                        marginBottom: 5
                    }}
                />
                <Skeleton
                    style={{
                        width: '170px',
                        height: '35px',
                        marginBottom: 10
                    }}
                />
            </div>
        </div>
    )

    return (
        <div className="services-list">
            {array.map((a, i) => (
                <SkeletonItem key={i} />
            ))}
        </div>
    )
}

const List = ({ list, description, history, loading }) => {
    const { cartReducer: { arrayServices }, listReducer: { categories } } = useSelector(state => state);
    const dispatch = useDispatch();
    return (
        loading ?
            renderSkeleton()
            :
            list.length ?
                renderList(list, history, dispatch, arrayServices, categories)
                :
                <div className="services-notfound">
                    <span>Nenhum resultado encontrado{description ? ` com a descrição "${description}"` : null}</span>
                </div>
    )
}

export default memo(List)