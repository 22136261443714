import React, { useState } from 'react'
import { faUserCircle, faSortDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import ClickOutside from './ClickOutSide';

export default function LoggedMenu({ name }){
  const [open, setOpen] = useState(false)

  const logout = () => {
    localStorage.clear()
    window.location.reload()
  }

  return(
    <ClickOutside onClickOutside={() => { setOpen(false) }}>
    <div className='logged-menu'>
      <div onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={faUserCircle} style={{ height:'100%', width: '25px', marginRight: '5px' }} color='white' aria-hidden="false" />
            <h6>{name} <FontAwesomeIcon icon={faSortDown} color='white' aria-hidden="false" /></h6>
      </div>

      {open &&
          <section className='logged-sub-menu' >
            <div onClick={logout}>
              <span>Sair</span>
            </div>
          </section>
      }
    </div>
    </ClickOutside>
  )
}