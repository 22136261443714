import React from 'react'
import SelectInstallments from './SelectInstallments'
import { useDispatch, useSelector } from 'react-redux'

import CreditCard from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import Amex from '../../../../../../assets/img/Cartoes/Amex.jpeg'
import Aura from '../../../../../../assets/img/Cartoes/Aura.jpg'
import Diners from '../../../../../../assets/img/Cartoes/Diners.png'
import ELO from '../../../../../../assets/img/Cartoes/ELO.png'
import JCB from '../../../../../../assets/img/Cartoes/JCB.png'
import Mastercard from '../../../../../../assets/img/Cartoes/Mastercard.png'
import Visa from '../../../../../../assets/img/Cartoes/Visa.png'
import { onlyNumbers } from '../../../../../../normalize';
import { handleCreditCard, handleChange } from '../../../../reduxCart/cartAction'

export default function CreditCardPayment({ installments, total }) {
  const months = new Array(12).fill()
  const years = new Array(21).fill()
  const yearToday = parseInt(new Date().toISOString().substring(0, 2));

  const { cartReducer: { type, creditCard } } = useSelector(state => state);
  const { cardNumber, cvv, year, month, focus } = creditCard
  const dispatch = useDispatch();

  const handleCallback = (type, isValid) => {
    const obj = { ...creditCard }
    obj.brand = type.issuer
    obj.isValid = isValid

    dispatch(handleChange(obj, 'creditCard'))
  }

  return(
    <>
    <section className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
      <div style={{ padding: '0' }}>
        <div>
          <input readOnly id='credit-card' name='radio-form-payments' type='radio' checked={type === 'credit-card'}/>
          <label htmlFor="credit-card"><strong>Cartão de Crédito</strong></label>
        </div>

        <div style={{ display: 'flex' }}>
          <div>
            <label>Número do Cartão</label>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <input 
                type='text' 
                className='form-control foco-input' 
                value={cardNumber}
                maxLength={16}
                onChange={ e => dispatch(handleCreditCard(creditCard, onlyNumbers(e.target.value), 'cardNumber')) }
                onFocus={() => dispatch(handleCreditCard(creditCard, 'number', 'focus')) }
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ padding: 0 }}>
            <label htmlFor='select-month'>Data de Expiração</label>

            <div style={{ display: 'flex' }}>
              <select 
                id='select-month' 
                className='form-control foco-input' 
                onChange={e => dispatch(handleCreditCard(creditCard, e.target.value, 'month'))}
                onFocus={() => dispatch(handleCreditCard(creditCard, 'expiry', 'focus'))}
              >
              <option value=''>Mês</option>
              {
                  months.map((item, index) => {
                      if (index < 9) {
                          return (<option key={index} value={'0' + parseInt(index + 1)}>0{index + 1}</option>)
                      } else {
                          return (<option key={index} value={index + 1}>{index + 1}</option>)
                      }
                  })
              }
              </select>

              <select 
                id='select-year' 
                className='form-control foco-input' 
                style={{ marginLeft: 5 }} 
                onFocus={() => dispatch(handleCreditCard(creditCard, 'expiry', 'focus'))}
                onChange={e => dispatch(handleCreditCard(creditCard, (yearToday + e.target.value), 'year'))}
              >
                <option value=''>Ano</option>
                {
                  years.map((item, index) => {
                      return (<option key={index} value={yearToday + index}>{yearToday + index}</option>)
                  })
                }
              </select>
            </div>
          </div>
        </div>

        <span style={{ display: 'flex', padding: 5 }}>
          <div><img alt='Visa' src={Visa} style={{ width: '35px', height: '10px' }} /></div>
          <div><img alt='Mastercard' src={Mastercard} style={{ width: '35px', height: '25px' }} /></div>
          <div><img alt='ELO' src={ELO} style={{ width: '40px', height: '15px' }} /></div>
          <div><img alt='Amex' src={Amex} style={{ width: '25px', height: '25px' }} /></div>
          <div><img alt='Diners' src={Diners} style={{ width: '30px', height: '25px' }} /></div>
          <div><img alt='JCB' src={JCB} style={{ width: '35px', height: '25px' }} /></div>
          <div><img alt='Aura' src={Aura} style={{ width: '35px', height: '25px' }} /></div>
        </span>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%' }}>
            <label>Código de segurança</label>
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <input 
                type='text' 
                className='form-control foco-input' 
                value={cvv} maxLength={4}
                onChange={ e => dispatch(handleCreditCard(creditCard, onlyNumbers(e.target.value), 'cvv')) }
                onFocus={() => dispatch(handleCreditCard(creditCard, 'cvc', 'focus'))}
              />
            </div>
          </div>

          <div style={{ width: '60%' }}>
            <label htmlFor='select-installments'>Parcelamento</label>
            <div>
              <SelectInstallments installments={installments} total={total} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section style={{ justifyContent: 'center' }}>
      <CreditCard
        cvc={cvv}
        locale={{ valid: 'Validade' }}
        placeholders={{ name: '' }}
        // expiry={this.renderExpiry(creditCard)}
        expiry={month + '/' + year}
        focused={focus}
        name={''}
        acceptedCards={['visa', 'mastercard', 'elo', 'amex', 'dinersclub', 'jcb', 'aura']}
        number={cardNumber}
        callback={handleCallback}
      />
    </section>
    </>
  )
}