import React from "react";
// import Header from '../../components/Header'
import Carrinho from './Carrinho'
import './styles.css'

function Items() {
  return (
    <div className='container-itens'>
      {/* <Header /> */}
      {/* TO DO */}
      <Carrinho/>
    </div>
  )
}

export default Items;
