import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { filterCategory, clearFilter, checkQuery } from './redux/listActions'
import './styles.css'
import List from './List'
import { api, config } from '../../../../services/api'
import Skeleton from "../../../../components/Skeleton";

function Services({ history }) {
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const { listReducer: {
        search,
        categories,
        categoriesFilterd,
        subCategories,
        online,
        presencial,
    } } = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(() => {
        initialize()
    }, [])

    async function initialize() {
        const getList = async () => {
            try {
                await api.get(config.endpoint + `search-service`)
                    .then(resp => {

                        resp.data.service
                            .filter(c => c.Categories.Name === 'Treinamentos')
                            .map(c => c.Description = 'Treinamento ' + c.Description)

                        setList(resp.data.service.filter(s => s.Active === 1))
                    })
            } catch (err) {
                console.log(err)
                setList([])
            }
        }

        const getCategories = async () => {
            try {
                await api.get(config.endpoint + 'categories')
                    .then(resp => {
                        let array = resp.data
                        for (let i = 0; i < array.length; i++) {
                            const obj = { ...array[i], checked: false }
                            array[i] = obj
                        }
                        dispatch([
                            {
                                type: "CHANGE_VALUE",
                                payload: array,
                                payloadType: 'categories'
                            },
                            checkQuery(array, [])
                        ])
                    })
            } catch (err) {
                console.log(err)
                dispatch({
                    type: "CHANGE_VALUE",
                    payload: [],
                    payloadType: 'categories'
                })
            }
        }

        await getList()
        await getCategories()
        setLoading(false)
    }

    function filterList(l) {
        for (let i = 0; i < categoriesFilterd.length; i++) {
            if (l.CategoryId === categoriesFilterd[i].id) return true
        }
        return false
    }

    function filterTraining(category, subCategory) {
        let exist
        for (let i = 0; i < categoriesFilterd.length; i++) {
            if (categoriesFilterd[i].id === category.id) {
                exist = true
            }
        }

        if (!exist) {
            let newArray = categoriesFilterd.slice()
            newArray.push(category)
            // setCategoriesFilterd(newArray)
            dispatch({
                type: 'CHANGE_VALUE',
                payload: newArray,
                payloadType: 'categoriesFilterd'
            })
        }

        const array = categories
        for (let i = 0; i < array.length; i++) {
            if (category.id === array[i].id) {
                array[i].checked = true
                dispatch({
                    type: 'CHANGE_VALUE',
                    payload: array,
                    payloadType: 'categories'
                })
            }
        }
        document.getElementById(`filter-item${category.id}`).checked = true;
        applyFilterSubCategory(subCategory)
    }

    function applyFilterSubCategory(subCategory) {
        for (let i = 0; i < subCategories.length; i++) {
            if (subCategories[i].Description === subCategory) {
                return dispatch({
                    type: "CHANGE_VALUE",
                    payload: subCategories.filter(s => s.Description !== subCategory),
                    payloadType: 'subCategories'
                })
            }
        }
        let newArray = subCategories.slice()
        newArray.push({ Description: subCategory })
        return dispatch({
            type: "CHANGE_VALUE",
            payload: newArray,
            payloadType: 'subCategories'
        })
    }

    function filterListSub(l) {
        if (!l.SubCategoryId || !subCategories.length) return true
        for (let i = 0; i < subCategories.length; i++) {
            if (l.SubCategories.Name === subCategories[i].Description) return true
        }
        return false
    }

    function renderList() {
        return <List list={categoriesFilterd.length ?
            list.filter(l => filterList(l) && filterListSub(l))
            : search !== '' ?
                list.filter(l => l.Description.toLowerCase().indexOf(search.toLowerCase()) > -1)
                : list}
            description={search}
            history={history}
            loading={loading}
        />
    }

    function renderLoadingCategories() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ marginBottom: 10, height: '20px' }} />
                <Skeleton style={{ height: '20px' }} />
            </div>
        )
    }

    return (
        <div className="services-content">
            <div className="services-filter">
                <div className="categories-filter">
                    <div className="categories-filter-title">
                        <span>Categorias selecionadas ({categoriesFilterd.length + subCategories.length})</span>
                        <br />
                        <span onClick={() => dispatch([
                            clearFilter(categories),
                            {
                                type: 'CHANGE_VALUE',
                                payload: '',
                                payloadType: 'search'
                            }
                        ])}>Limpar todos</span>
                    </div>
                    {
                        loading ?
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center'
                                }}
                            >
                                {renderLoadingCategories()}
                            </div>
                            :

                            categories.map(c => (
                                <section key={c.id}>
                                    <input
                                        id={`filter-item${c.id}`}
                                        checked={c.checked}
                                        onChange={() => { }}
                                        onClick={() => dispatch([
                                            filterCategory(c, categoriesFilterd),
                                            {
                                                type: "CHANGE_VALUE",
                                                payload: '',
                                                payloadType: 'search'
                                            }
                                        ])}
                                        type="checkbox"
                                    />
                                    <label htmlFor={`filter-item${c.id}`}>{c.Name}</label>
                                    {c.Name === 'Treinamentos' &&
                                        <div>
                                            <section>
                                                <input id="Online" type="checkbox" checked={online} onChange={() => { }} onClick={() => {
                                                    filterTraining(c, "Online")
                                                    dispatch(
                                                        {
                                                            type: "CHANGE_VALUE",
                                                            payload: !online,
                                                            payloadType: 'online'
                                                        },
                                                        {
                                                            type: "CHANGE_VALUE",
                                                            payload: '',
                                                            payloadType: 'search'
                                                        }
                                                    )
                                                }} />
                                                <label htmlFor="Online">Online</label>
                                            </section>
                                            <section>
                                                <input id="Presencial" type="checkbox" checked={presencial} onChange={() => { }} onClick={() => {
                                                    filterTraining(c, "Presencial")
                                                    dispatch(
                                                        {
                                                            type: "CHANGE_VALUE",
                                                            payload: !presencial,
                                                            payloadType: 'presencial'
                                                        },
                                                        {
                                                            type: "CHANGE_VALUE",
                                                            payload: '',
                                                            payloadType: 'search'
                                                        }
                                                    )
                                                }} />
                                                <label htmlFor="Presencial">Presencial</label>
                                            </section>
                                        </div>
                                    }
                                </section>
                            ))}
                </div>
            </div>
            {renderList()}
        </div>
    )
}
export default withRouter(Services);