import React from 'react'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './styles.css'
import { withRouter } from 'react-router-dom'

function DisloggedMenu({ history }){
  return(
    <div className='dislogged-menu'>
      <div onClick={() => history.push('/login')}>
            <FontAwesomeIcon icon={faUserCircle} style={{ height:'100%', width: '25px', marginRight: '5px' }} color='white' aria-hidden="false" />
            <h6>Entrar</h6>
      </div>
    </div>
  )
}

export default withRouter(DisloggedMenu)