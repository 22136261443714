import React, { useState, useEffect } from "react";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./styles.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../../../assets/img/logo-3.png";

import { onlyNumbers } from "../../../../normalize";
import { api, config } from '../../../../services/api'

function Login({ history, location }) {
    const dispatch = useDispatch();

    const [show, setShow] = useState(true)
    const [cnpj, setCnpj] = useState('')
    const [password, setPassword] = useState('')
    const [fromURL, setFromURL] = useState(false)

    useEffect(() => {
        const getURLparams = async () => {
            const params = new URLSearchParams(location.search) // classe para pegar queryString da URL
            let URLcnpj = params.get('login')
            let senha = params.get('senha')

            if (URLcnpj && senha) {
                await setCnpj(onlyNumbers(URLcnpj))
                await setPassword(senha)
                setFromURL(true)
            }
        }
        getURLparams()

    }, [location.search])


    useEffect(() => {
        if (fromURL) login()
    }, [fromURL])


    async function login(e) {
        if (e) {
            e.preventDefault()
        }

        try {
            await api.post(config.endpoint + `authenticate`, {
                Cnpj: cnpj,
                Password: password
            }).then(resp => {
                if(resp.data.isBloqueado === 1){
                    return toastr.warning(
                        "Acesso bloqueado, por favor, verifique sua situação junto ao suporte técnico do Workmotor."
                    );
                }else{
                    localStorage.clear()
                    localStorage.setItem('CNPJ', resp.data.login)
                    localStorage.setItem('RAZAO_SOCIAL', resp.data.nome)
                    localStorage.setItem('NOME_FANTASIA', resp.data.nome)
                    localStorage.setItem('EMAIL', resp.data.email)
                    toastr.success("Autenticação realizada com sucesso.");

                    const params = new URLSearchParams(location.search)
                    let cartPage = params.get('cart-page')
                    if (cartPage) {
                        dispatch({
                            type: 'HANDLE_CHANGE',
                            payload: 2,
                            payloadType: 'step'
                        })

                        history.push('/store/items')
                    } else {
                        history.push('/')
                    }
                }                
            })
        } catch (err) {
            return toastr.warning(
                "CNPJ e/ou Senha Inválida. Por favor, verifique."
            );
        }
    }

    return (
        <div className="Login-container">
            <div className="Login">
                <div className="Login-card">
                    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', textAlign: 'center', alignItems: 'center' }}>
                        <img className="logo-img" src={Logo} alt="Loja WorkMotor" />
                    </div>

                    <form className="Login-form" onSubmit={login}>

                        <div className="Login-field">
                            <div className="Login-icon">
                                <FontAwesomeIcon
                                    icon={faUser}
                                    aria-hidden="false"
                                />
                            </div>
                            <input
                                placeholder="Informe o CNPJ da sua empresa"
                                maxLength="20"
                                value={cnpj}
                                onChange={event => setCnpj(onlyNumbers(event.target.value))}
                            />
                        </div>

                        <div className="Login-field">
                            <div className="Login-icon">
                                <FontAwesomeIcon
                                    icon={faLock}
                                    aria-hidden="false"
                                />
                            </div>
                            <input
                                placeholder="Informe a sua senha de acesso"
                                type={show ? 'password' : 'text'}
                                autoComplete="new-password"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                            />
                            <div className="Login-icon-left" onClick={() => setShow(!show)}>
                                <FontAwesomeIcon
                                    icon={show ? faEyeSlash : faEye}
                                    aria-hidden="false"
                                />
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                textAlign: "center",
                                marginBottom: "20px",
                                alignItems: "center"
                            }}
                        >
                            <span style={{ marginTop: "10px", color: 'red' }}>Seu login e senha é o mesmo da área do cliente</span>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                textAlign: "center",
                                alignItems: "center"
                            }}>
                            <button
                                type="submit"
                                disabled={!cnpj || !password}
                            >
                                Entrar
                                </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    )
}

export default withRouter(Login)