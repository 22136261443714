import React, { useEffect } from "react";
import ProgressBarComponent from './progressBar/ProgressBar'
import ItemsData from './steps/ItemsData'
import Payment from './steps/Payment/index'
import Digest from './Digest'
import './styles.css'
import { useSelector, useDispatch } from "react-redux";
import { handleChange, createSale } from "../../reduxCart/cartAction";
import { toastr } from 'react-redux-toastr'
import AlertModal from '../../../../components/AlertModal/AlertModal'
import { withRouter } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

function Items({history}) {
  const dispatch = useDispatch();
  const { cartReducer } = useSelector(state => state)
  const { step, confirmModal, arrayServices, loading, digest } = cartReducer

  useEffect(() => {
    function clearDigest() {
      if (digest.items.length) {
        dispatch({
          type: 'HANDLE_CHANGE',
          payload: { items: [], sale: {} },
          payloadType: 'digest'
        })
      }
    }
    clearDigest()
  }, [])

  useEffect(() => {
    if (step === 2 && !localStorage.getItem('CNPJ')) return history.push('/login?cart-page=2')
  },[step])

  function nextPage() {
    const user = localStorage.getItem('CNPJ')
    if (!user) {
      history.push('/login?cart-page=2')
    }
    // setSteps(step + 1)
    dispatch({
      type: 'HANDLE_CHANGE',
      payload: step + 1,
      payloadType: 'step'
    })
  }

  function previousPage() {
    // setSteps(step - 1)
    dispatch({
      type: 'HANDLE_CHANGE',
      payload: step - 1,
      payloadType: 'step'
    })
  }

  const validate = () => {
    const { bankSlip, creditCard, type } = cartReducer

    if (type === 'credit-card') {

      const { brand, isValid, month, year, cvv, cardNumber } = creditCard
      if (!isValid || brand === '' || month === '' || year === '' || cvv === '' || cardNumber === '') return toastr.warning('Por favor, revise os dados do cartão de crédito')

    } else if (type === 'bank-slip') {

      const { billingDay } = bankSlip

      if (billingDay === '') return toastr.warning('Por favor, selecione o dia do vencimento do boleto')

    } else {
      return toastr.error('Por favor, selecione uma forma de pagamento')
    }
    dispatch(handleChange(true, 'confirmModal'))
  }

  return (
    <>
      {loading &&
        <>
          <div className="div-loading">
            <Spinner animation="border" role="status" variant="success" />
            <FontAwesomeIcon icon={faDollarSign} className="animated-money bounce-money" />
          </div>
        </>
      }
      {!digest.items.length ?
        <div>
          {/* TO DO */}
          <ProgressBarComponent percent={step} nextPage={nextPage} previousPage={previousPage} stepProgress={step} cart={arrayServices.length} />
          {step === 1 && <ItemsData nextPage={nextPage} history={history} cart={arrayServices.length} />}
          {step === 2 && <Payment previousPage={previousPage} onSubmit={validate} />}
        </div>
        :
        <Digest digest={digest} />
      }
      <AlertModal
        show={confirmModal}
        message='Deseja confirmar o pagamento ?'
        onHide={() => dispatch(handleChange(false, 'confirmModal'))}
        onCancel={() => dispatch(handleChange(false, 'confirmModal'))}
        onSubmit={() => dispatch(createSale(cartReducer))}
      />
    </>
  )
}

export default withRouter(Items);
